import $ from 'jquery';
import 'jquery.dotdotdot/src/jquery.dotdotdot';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/tab';
import './ui/Modal';
import './ui/VideoHandler';
import {isIE, isTouchDevice} from './utilities/user-agent';
import throttle from 'lodash/throttle';
import Aside from './ui/Aside';
import Footer from './ui/Footer';
import Form from './ui/Form';
import Header from './ui/Header';
import OffCanvas from './ui/OffCanvas';

export default class Ui {
  constructor() {
    this.$window = $(window);
    this.$body = $('body');
    initHelperClasses.call(this);
    this.Header = new Header(this);
    this.Footer = new Footer();
    this.Form = new Form(this);
    this.OffCanvas = OffCanvas;
    this.Aside = Aside;

    initToTopButton.call(this);
    initSmoothAnchorScrolling.call(this);
    initTrippleDots.call(this);

    /*
     * Social Feed Slider
     */
    (async () => {
      const $socialFeed = $('#social-feed-area');
      if ($socialFeed.length) {
        import(/* webpackChunkName: "SocialFeed" */ './ui/SocialFeed');
      }
    })();
  }
}

/**
 * Copy from ui/ui.js
 */
function initHelperClasses() {
  if (isIE) {
    this.$body.addClass('is-IE');
  }

  this.$body.addClass(() => {
    return isTouchDevice ? 'isTouch' : 'noTouch';
  });
}

/**
 * Copy from ui/ui.js
 */
function initToTopButton() {
  const $button = $('#btn-to-top');
  let lastPixelScrolled = 0;

  this.$window.on('scroll.Ui.toTopButton', throttle(
    () => {
      const pixelScrolled = this.$window.scrollTop();
      if (pixelScrolled <= lastPixelScrolled) {
        $button.show();
      } else {
        $button.hide();
      }
      lastPixelScrolled = pixelScrolled;
    },
    125,
  ));

  $button.on('click', function() {
    $('html, body').animate({
      scrollTop: 0,
    }, 1000);
    return false;
  });
}

/**
 * Copy from ui/ui.js
 */
function initSmoothAnchorScrolling() {
  $('a[href*="#"]:not([href="#"]):not([data-toggle="tab"])').click(function() {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        $('html, body').animate({
          scrollTop: target.offset().top - 70,
        }, 1000, 'swing');
        return false;
      }
    }
  });
}

/**
 * Copy from ui/ui.js->trippleDots()
 */
function initTrippleDots() {
  $('.teaser-highlight-product-copytext').dotdotdot({
    ellipsis: ' ...',
    watch: true,
  });
}
