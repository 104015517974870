import $ from 'jquery';
import './campaign/Tracking';

export default class Campaign {
  constructor() {
    (async () => {
      const $form = $('#form-contestApplication');
      if ($form.length) {
        const {ContestApplicationForm} = await import(/* webpackChunkName: "ContestApplicationForm" */ './campaign/ContestApplicationForm');
        new ContestApplicationForm({
          $form: $form,
        });
      }
    })();

    const $scalingImages = $('.landing-page-qualitaetsversprechen-scaling-image');
    if ($scalingImages.length) {
      import(/* webpackChunkName: "ScalingImageAnimation" */ './campaign/ScalingImageAnimation');
    }

    /* move modal to body */
    $.each($('div.modal'), function(index, modal) {
      if ($(modal).parent().get(0).tagName != 'BODY') {
        $('body').append(modal);
      }
    });

    /* Open terms of use when directly accessed over hash value */
    var $modalConditionsOfParticipation = $('#modal-conditions-of-participation');
    if ($modalConditionsOfParticipation.length) {
      if (window.location.hash === '#termsOfUse-segment') {
        $modalConditionsOfParticipation.modal('show');
      }
      // Link from same page without reload
      const $termsOfUseLink = $('.inline-link[href$=termsOfUse-segment]');
      $termsOfUseLink.click(function() {
        $modalConditionsOfParticipation.modal('show');
      });
    }
  }
}
